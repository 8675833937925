import * as React from "react";
import Layout from "../../components/Layout";
import ContactForm from "../../components/ContactForm";
import { Helmet } from "react-helmet";

export default class Index extends React.Component {

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Contact David</title>
          <meta property="og:title" content="Contact David" />
        </Helmet>
        <article className="page page--contact">
          <section className="container-md">
            <div className="content col-md-6 mx-auto">
              <h1>Contact</h1>
              <ContactForm buttonLabel={'Contact David'} />
            </div>
          </section>
        </article>
      </Layout>
    );
  }
}
